$colors: (
  'black': #000,
  'black-hover': rgba(42, 40, 39, 0.75),
  'orange': #ffa200,
  'orange-light': rgb(252, 237, 216),
  'orange-hover': #c06e00,
  'blue': #37a8dc,
  'green': #42ca45,
  'green-light': rgba(1, 7, 1, 0.25),
  'green-medium': rgba(66, 202, 69, 0.5),
  'green-dark': rgba(66, 202, 69, 0.75),
  'gray': #b4b4b4,
  'gray-stroke': #ccc,
  'gray-subNav': #d9d9d9,
  'gray-background': #f2f2f2,
  'gray-gradient': linear-gradient(180deg, #ffffff 0%, #dcdcdc 100%),
  'gray-gradient-hover': linear-gradient(180deg, #dcdcdc 0%, #fff 100%),
  'login-background': #e0e0e0,
  'purple': #7926ae,
  'red': #de1e1e,
  'red-light': rgba(222, 30, 30, 0.25),
  'red-medium': rgba(222, 30, 30, 0.5),
  'red-dark': rgba(222, 30, 30, 0.75),
  'yellow': #ffe600,
  'white': #fff,
  'strat-darkGreen': rgb(215, 231, 213),
  'strat-green': rgb(226, 238, 224),
  'strat-lightGreen': rgb(231, 241, 229),
  'strat-yellow': rgb(246, 241, 200),
  'strat-darkYellow': rgb(250, 240, 157),
  'strat-lightRed': rgb(241, 212, 211),
  'strat-darkRed': rgb(236, 188, 187),
  'strat-gray': #e6e6e6
);

$textForColor: (
  'orange': 'white',
  'gray-gradient': 'black',
  'black': 'white',
  'yellow': 'black',
  'red': 'white',
  'red-light': 'black',
  'red-medium': 'black',
  'red-dark': 'white',
  'green': 'black',
  'green-light': 'black',
  'green-medium': 'black',
  'green-dark': 'black',
  'gray-background': 'black',
  'white': 'black'
);

$borderForColor: (
  'orange': 'gray-stroke',
  'gray-gradient': 'gray-stroke',
  'black': 'black'
);

$hoverForColor: (
  'orange': 'orange-hover',
  'gray-gradient': 'gray-gradient-hover',
  'black': 'black'
);

@mixin color($name, $prop: 'background', $options...) {
  $keys: keywords($options);
  @if map-get($keys, 'hover') {
    &:hover {
      $hoverColor: map-get($hoverForColor, $name);
      #{$prop}: map-get($colors, $hoverColor);
    }
  }

  @if map-get($keys, 'text') {
    $textColor: map-get($textForColor, $name);
    color: map-get($colors, $textColor);
  }

  @if map-get($keys, 'border') {
    $borderColor: map-get($borderForColor, $name);
    border-color: map-get($colors, $borderColor);
  }

  #{$prop}: map-get($colors, $name);
}

@mixin colorHover($name, $prop: 'background-color') {
  @include color($name, $hover: true);
}

@mixin colorStyling($keys...) {
  &.orange {
    @include color('orange', $keys...);
  }
  &.gray-gradient {
    @include color('gray-gradient', $keys...);
  }
  &.green {
    @include color('green', $keys...);
  }
  &.green-light {
    @include color('green-light', $keys...);
  }
  &.green-dark {
    @include color('green-dark', $keys...);
  }
  &.green-medium {
    @include color('green-dark', $keys...);
  }
  &.black {
    @include color('black', $keys...);
  }
  &.yellow {
    @include color('yellow', $keys...);
  }
  &.red {
    @include color('red', $keys...);
  }
  &.red-light {
    @include color('red-light', $keys...);
  }
  &.red-dark {
    @include color('red-dark', $keys...);
  }
  &.red-medium {
    @include color('red-dark', $keys...);
  }
  &.gray-background {
    @include color('gray-background', $keys...);
  }
  &.gray {
    @include color('gray', $keys...);
  }
  &.white {
    @include color('white', $keys...);
  }
  &.yellow-light {
    @include color('yellow-light', $keys...);
  }
  &.green-quad {
    @include color('green-quad', $keys...);
  }
  &.white {
    @include color('white', $keys...);
  }
  &.strat-darkGreen {
    @include color('strat-darkGreen', $keys...);
  }
  &.strat-lightGreen {
    @include color('strat-lightGreen', $keys...);
  }
  &.strat-yellow {
    @include color('strat-yellow', $keys...);
  }
  &.strat-lightRed {
    @include color('strat-lightRed', $keys...);
  }
  &.strat-darkRed {
    @include color('strat-darkRed', $keys...);
  }
  &.strat-gray {
    @include color('strat-gray', $keys...);
  }
  &.strat-darkYellow {
    @include color('strat-darkYellow', $keys...);
  }
  &.strat-green {
    @include color('strat-green', $keys...);
  }
}

$barFillColor: hsl(40, 76%, 70%); // #EDC678
$barLineColor: hsl(38, 100%, 27%); // #885500

$topNavColor: map-get($colors, 'black');
