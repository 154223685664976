.input {
  display: flex;
  flex-flow: column nowrap;
  padding: 5px;
}

.label {
  font-size: 14px;
  line-height: 19px;
}

.checkboxColumns {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.checkboxColumns .checkboxinput {
  margin-top: 0px;
}

.checkboxinput {
  display: flex;
  flex-flow: column nowrap;
  padding: 5px;
  font-size: 1em;
  margin-top: 10px;
}

.checkboxinput:first-of-type {
  margin-top: 0px;
}

.checkboxDiv {
  display: flex;
  justify-content: flex-start;
}

.checkbox-label {
  position: relative;
  padding-left: 3px;
  padding-top: 22px;
  cursor: pointer;
  font-size: 14px;
}

.checkbox-label--morePadding {
  position: relative;
  padding-left: 7px;
  padding-top: 22px;
  cursor: pointer;
  font-size: 14px;
}

.checkbox-label--morePadding input,
.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.checkbox-label--morePadding:hover input ~ .checkbox,
.checkbox-label:hover input ~ .checkbox {
  background-color: #c06e00;
}

.checkbox-label--morePadding input:checked ~ .checkbox,
.checkbox-label input:checked ~ .checkbox {
  background-color: #ffa200;
}

.checkbox:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-label--morePadding input:checked ~ .checkbox:after,
.checkbox-label input:checked ~ .checkbox:after {
  display: block;
}

.checkbox-label--morePadding .checkbox:after,
.checkbox-label .checkbox:after {
  background-image: url(../../../assets/images/checkmark.png);
  background-size: 11px 10px;
  margin: 5px 4px;
  width: 11px;
  height: 10px;
  object-fit: contain;
}

.customTheme {
  opacity: 1 !important;
  border-radius: 4px;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.5));
  min-width: 100px;
  max-width: 321px;
  height: fit-content;
  font-size: 12px !important;
  padding: 10px !important;
  text-align: left;
  pointer-events: auto !important;
}
